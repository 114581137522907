
*::before,
*::after {
  box-sizing: border-box;
}
:root {
  --font-size-general : 16px;
  --font-size-header: 32px;
  --font-size-title: 20px;
}


body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #888;
}

body::-webkit-scrollbar-thumb:hover {
  cursor: grabbing;
  background: #555;
}
.jumbotron {
    background: linear-gradient(rgba(40, 120, 235, 0.9), rgba(40, 120, 235, 0.9)), url(../public/img/Institute/header.jpg), no-repeat center center;
    background-size: cover;
  }

  .jumbotron_about {
    background: linear-gradient(rgba(40, 120, 235, 0.9), rgba(40, 120, 235, 0.9)), url(../public/img/Institute/course_header.jpg), no-repeat center center;
    background-size: cover;
    
    
  }
 
  .jumbotron.page-header {
    background: linear-gradient(rgba(40, 120, 235, 0.9), rgba(40, 120, 235, 0.9)), url(../public/img/Institute/course_header.jpg), no-repeat center center;
    background-size: cover;
  }

  .overlay-top::before,
  .overlay-bottom::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 85px;
    left: 0;
    z-index: 1;
  }
  
  .overlay-top::before {
    top: 0;
    background: url(../public/img/overlay-top.png) top center no-repeat;
    background-size: contain;
  }
  
  .overlay-bottom::after {
    bottom: 0;
    background: url(../public/img/overlay-bottom.png) bottom center no-repeat;
    background-size: contain;
  }

  .bg-image {
    background: linear-gradient(rgba(40, 120, 235, 0.05), rgba(40, 120, 235, 0.05)), url(../public/img/bg-image.jpg);
    background-attachment: fixed;
  }

  .section-titles h6 {
    position: absolute;
   
    width: 100%;
   
   
    color: #0057b3 !important;
    font-weight: 600;
    
  }
  .course_content{
    text-transform: uppercase;
    content: "";
    width: 100%;
    font-weight: 600;
  
    color: #0057b3 !important;
    
  }
  .btn-icon {
    width: 77px;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .mr-4, .mx-4 {
    margin-right: 1.5rem !important;
}

.nav-item{
  font-size: var(--font-size-general);
}
.dropdown-item{
  font-size: var(--font-size-general);

}
h1{
  font-size: var(--font-size-header);
}
p{
  font-size: var(--font-size-general);
}
h6,h5{
  font-size: var(--font-size-general);
}
h4{
  font-size: var(--font-size-title);
}

.course_title{
  font-size: var(--font-size-title);
}

.dropdown-menu-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px; /* Adjust the gap between items if needed */
}

.courses-item img {
  position: relative;
  margin-top: 40px;
  transition: .5s;
}

.courses-list-item img {
  position: relative;
  transition: .5s;
}

.courses-item .courses-text,
.courses-list-item .courses-text {
  position: absolute;
  width: 100%;
  height: calc(100% + 86px);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(to bottom, rgba(18, 15, 45, 0), #120f2d);
  transition: .5s;
  z-index: 1;
}

.courses-list-item .courses-text {
  height: 100%;
}

.courses-item:hover img {
  margin-top: 0px;
}

.courses-list-item:hover img {
  transform: scale(1.2);
}

.courses-item:hover .courses-text {
  height: calc(100% + 40px);
}



.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-fluid-hotel {
  max-width: 100%;
  height: auto;
}

.img-fluids {
  height: 65vh;
  width: 100%;
}

@media (max-width: 768px) { /* Adjust this breakpoint as needed */
  .img-fluids {
    height: 25vh; /* Allow the height to adjust automatically */
    width: 100%; /* Ensure the width fills the container */
   
  }}
  @media (max-width: 667px) { /* Adjust this breakpoint as needed */
    .img-fluid {
      height: 45vh; /* Allow the height to adjust automatically */
      width: 100%; /* Ensure the width fills the container */
     
    }

}

@media (max-width: 1024px) { 
  .img-fluids {
    height: 20vh; 
    width: 100%; 
   
  }

}

/* list group */

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: false;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #6B6A75;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
/* .... */

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2878EB;
  border-color: #2878EB;
}

.course_detail_list{
  margin-right: 20px;
}

.badge-primary {
  color: #fff;
  background-color: #2878EB;
}





.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}


.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}


.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
  
select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.team-carousel-institute .owl-nav,
.related-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 60px;
  top: calc(50% - 30px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  
}

.team-carousel-institute .owl-nav .owl-prev,
.team-carousel-institute .owl-nav .owl-next,
.related-carousel .owl-nav .owl-prev,
.related-carousel .owl-nav .owl-next {
  background: #2878EB;
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #2878EB;
  font-size: 22px;
  transition: .4s;
}





select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #6B6A75;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}





.top_header_menu{
  background: #0919ae;
}
.newsletter{
 background-color: #1363c6;
}



.video .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}


@keyframes pulse-border {
  0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
  }

  100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
  }
}

/* Shared styles for modals */
#videoModal, #video1Modal, #video2Modal,#video3Modal,#video4Modal,#video5Modal {
  z-index: 99999;
}

#videoModal .modal-dialog, #video1Modal .modal-dialog, #video2Modal .modal-dialog, #video3Modal .modal-dialog,#video4Modal .modal-dialog ,
#video5Modal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body, #video1Modal .modal-body, #video2Modal .modal-body,#video3Modal .modal-body,#video4Modal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close, #video1Modal .close, #video2Modal .close,#video3Modal .close,#video4Modal .close ,#video5Modal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #FFFFFF;
  background: #000000;
  opacity: 1;
}

.video-institute
 {
  position: relative;
  height: 100%;
  min-height: 500px;
  background: linear-gradient(rgba(15, 23, 43, .1), rgba(15, 23, 43, .1)), url(../public/img/Institute/video.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


.video-institute .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video-institute .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-institute .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video-institute .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-institute .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

/* all course page */

.inside-page__heading { 
  padding-bottom: 1rem; 
  width: 100%;
}

/* Mixed */

/* For both inside page's main heading and 'view me' text on card front cover */
.inside-page__heading,
.card-front__text-view {
  font-size: 17px;
  font-weight: 700;
  margin-top: 2px;
  text-align: center;
  color: #007bff;


}

/* Front cover */

.card-front__tp { color: #fafbfa; }

/* Back cover */

/* For inside page's body text */
/* .inside-page__text {
  color: #333;
  margin: 12px;
} */


/* Container to hold all cards in one place */

.card-area {
  
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  padding: 20px;
  gap: 20px; 
}

.card-section {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}


.card {
  
  background-color: rgba(0, 0, 0, .05);
  box-shadow: -.1rem 1.7rem 6.6rem -3.2rem rgba(0, 0, 0, 0.5);
  height: 18rem;
  position: relative;
  transition: all 1s ease;
  width: 18rem;
  margin-top: 20px;
}

/* @media screen and (min-width: 767px) {
  .card-area {
    
    justify-content: space-around;
  
  }
  .card { 
      margin: 10px;
     
  }
} */
@media screen and (min-width: 767px) {
  .card-area {
    
   
    width: 97%;
  }
  .card { 
      margin: 10px;
     
  }
}

@media screen and (max-width: 1700px) {
  .card-area {
    
   
    width: 101rem;
  }
  .card { 
      margin: 10px;
     
  }
}


.card:hover {
  box-shadow: -.1rem 1.7rem 6.6rem -3.2rem rgba(0, 0, 0, 0.75);
  width: 30rem;
 
}


.flip-card {
  height: 18rem;
  perspective: 100rem;
  position: absolute;
  right: 0;
  transition: all 1s ease;
  visibility: hidden;
  width: 18rem;
  z-index: 100;
}

.flip-card > * {
  visibility: visible;
}

.flip-card__container {
  height: 100%;
  position: absolute;
  right: 0;
  transform-origin: left;
  transform-style: preserve-3d;
  transition: all 1s ease;
  width: 100%;
}

.card-front,
.card-back {
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.card-front {
  background-color: #fafbfa;
}

.card-front__tp {
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
  display: flex;
  flex-direction: column;
  height: 15rem;
  justify-content: center;
  padding: .75rem;
}

.card-front__bt {
  align-items: center;
  display: flex;
  justify-content: center;
}

.card-back {
  background-color: #fafbfa;
  transform: rotateY(180deg);
}

.inside-page {
  background-color: #fafbfa;
  box-shadow: inset 20rem 0px 5rem -2.5rem rgba(0, 0, 0, 0.25);
  height: 100%;
  padding: 1rem;
  position: absolute;
  right: 0;
  transition: all 1s ease;
  width: 18rem;
  z-index: 1;
}

.inside-page__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  
}
.inside-page__btn {
  background-color: transparent;
  border: 3px solid;
  border-radius: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1rem;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-decoration: none;
  transition: all .3s ease;
  width: 90%;
  z-index: 10;
  text-align: center;
}

.inside-page__btn::before { 
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scaleY(0);
  transition: all .3s ease;
  width: 100%;
  z-index: -1;
}

.inside-page__btn--camping { 
  border-color: #007bff;
  color: #000000;
}

.inside-page__btn--camping::before { 
  background-color: #007bff;
}

.inside-page__btn:hover { 
  color: #fafbfa;
}

.inside-page__btn:hover::before { 
  transform: scaleY(1);
}
.inside-page__text {
  color: #333;
}



.card:hover .flip-card__container {
  transform: rotateY(-180deg);
}

.card:hover .inside-page {
  box-shadow: inset 1rem 0px 5rem -2.5rem rgba(0, 0, 0, 0.1);
}

.card-front__image {
  width: 100%;
  height: 14rem;
}

.card-back img {
  height: 18rem;
  width: 18rem;
}



 @import url("https://pro.fontawesome.com/releases/v6.0.0-beta1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;500;700&display=swap");



.process {
  --color: rgb(255, 255, 255);
  --bgColor: rgba(245, 245, 245);
  min-height: 60vh;
  display: grid;
  align-content: center;
  gap: 2rem;
  padding: 2rem;
  font-family: "Exo 2", sans-serif;
  color: var(--color);
  background:#1363C6;
}

.process h1 { text-align: center }

.process ol {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  list-style-type: none;
  gap: 5rem;
}

.process li:nth-child(6n + 1) { --accent-color: #b8df4e }
.process li:nth-child(6n + 2) { --accent-color: #4cbccb }
.process li:nth-child(6n + 3) { --accent-color: #7197d3 }
.process li:nth-child(6n + 4) { --accent-color: #ae78cb }
.process li:nth-child(6n + 5) { --accent-color: #7dc7a4 }
.process li:nth-child(6n + 6) { --accent-color: #f078c2 }

.process ol li {
  counter-increment: stepnr;
  width: 18rem;
  --borderS: 2rem;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: calc(var(--borderS) + 2rem);
  position: relative;
}
.process ol li::before,
.process ol li::after {
  inset: 0;
  position: absolute;
  border-radius: 50%;
  border: var(--borderS) solid var(--bgColor);
  line-height: 1.1;
}
.process ol li::before {
  content: counter(stepnr);
  color: var(--accent-color);
  padding-left: 10rem;
  font-size: 12rem;
  font-weight: 700;
  overflow: hidden;
}

.process ol li::after {
  content: "";
  filter: drop-shadow(-0.25rem 0.25rem 0.0675rem rgba(0, 0, 0, 0.75)) blur(5px);
}

.process ol li > * { width: 7.5rem }
.process ol li .icon { font-size: 2rem; color: var(--accent-color); text-align: center }
.process ol li .title { font-size: 2rem; font-weight: 500 }
.process ol li .descr { font-size: 0.8rem; font-weight: 300 ;padding: 0px 8px; }

.process .bottom-heading {
  position: absolute;
  bottom: -60px; /* Adjust as needed */
  right: 0px;
  width: 100%;
  text-align: center;
  color: #e9ecef;
}

.about p span{
  color: #000000;
  font-weight: 600;
}



.choose_card_container {
  display: flex;
  justify-content: space-around; /* Space between the cards */
  flex-wrap: wrap; /* Wraps the cards to the next line if necessary */
  gap: 20px; /* Space between the cards */
} 

.choose_card {
  width: 300px;
  height: 200px;
  perspective: 1000px;
}

.choose_card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.999s;
}

.choose_card:hover .choose_card-inner {
  transform: rotateY(180deg);
}

.choose_card-front,
.choose_card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.choose_card-front {
  background-color: #ffffff;
  color: #000000;
  /* display: flex; */
  text-align: center;
  align-items: center;
  border: 1px solid #f5e9e9;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(0deg);
}

.choose_card-back {
  background-color: #ffffff;
  color: #000000;
  /* display: flex; */
  text-align: center;
  align-items: center;
  border: 1px solid #f5e9e9;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(180deg);
}

.icon-title-container{
  margin-top: 10%;
}


#accordionExample li{
  list-style:none ;
  margin: 5px;
  font-weight: 600;
}
.accordion-item{
  background-color:#e4effc;
  border: 1px solid rgba(0, 0, 0, .125);
}

.accordion-item .accordion-button {
  background-color: #0057b3; /* Primary color */
  color: #ffffff; /* White text */
}


.accordion-item .accordion-button:hover {
  background-color: #0057b3; /* Primary color */
  color: #ffffff; /* White text */
}

.accordion-button:focus {
  box-shadow: none; /* Remove default focus shadow */
}

.accordion .collapse {
  background-color: #e4effc; /* White background for the collapse */
  border: 1px solid #ddd; /* Light gray border */
}

.accordion .collapse ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.accordion .collapse ul li {
  font-size: 16px; /* Font size */
  margin-bottom: 8px; /* Space between items */
}
.dropdown-item .dropdown_header{
  font-size: 18px;
  font-weight: 500;
  color: white;
  background: #0057b3;
  text-align: center;

}

.overlay-to-1::before,
  .overlay-bottom-1::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 85px;
    left: 0;
    z-index: 1;
  }
  

  
  .overlay-bottom-1::after {
    bottom: 0;
    background: url(../public/img/overlay-bottom-1.png) bottom center no-repeat;
    background-size: contain;
  }




/* 

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@700&display=swap");

.course_card_container {
  display: grid;
  place-items: center;
  height: 100vh;
  --brand-color: hsl(46, 100%, 50%);
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
  
  --font-title: "Montserrat", sans-serif;
  --font-text: "Lato", sans-serif;
}

.course_card_container h2 {
  font-size: 2.25rem;
  font-family: var(--font-title);
  color: var(--white);
  line-height: 1.1;
}

.course_card_container p {
  font-family: var(--font-text);
  font-size: 1rem;
  line-height: 1.5;
  color: var(--white);
}

.course_card_container .flow > * + * {
  margin-top: var(--flow-space, 1em);
}



.course_card_container .card {
  display: grid;
  place-items: center;
  width: 80vw;
  max-width: 21.875rem;
  height: 28.125rem;
  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}

.course_card_container .card > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.course_card_container .card__background {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}

.course_card_container .card__content {
  --flow-space: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  height: 55%;
  padding: 12% 1.25rem 1.875rem;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.3) 10%,
    hsl(0, 0%, 0%) 100%
  );
}

.course_card_container .card__content--container {
  --flow-space: 1.25rem;
}

.course_card_container .card__title {
  position: relative;
  width: fit-content;
  width: -moz-fit-content; 
}

.course_card_container .card__title::after {
  content: "";
  position: absolute;
  height: 0.3125rem;
  width: calc(100% + 1.25rem);
  bottom: calc((1.25rem - 0.5rem) * -1);
  left: -1.25rem;
  background-color: var(--brand-color);
}

.course_card_container .card__button {
  padding: 0.75em 1.6em;
  width: fit-content;
  width: -moz-fit-content; 
  font-variant: small-caps;
  font-weight: bold;
  border-radius: 0.45em;
  border: none;
  background-color: var(--brand-color);
  font-family: var(--font-title);
  font-size: 1.125rem;
  color: var(--black);
}

.course_card_container .card__button:focus {
  outline: 2px solid black;
  outline-offset: -5px;
}

@media (any-hover: hover) and (any-pointer: fine) {
  .course_card_container .card__content {
    transform: translateY(62%);
    transition: transform 500ms ease-out;
    transition-delay: 500ms;
  }

  .course_card_container .card__title::after {
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 1000ms ease-in, transform 500ms ease-out;
    transition-delay: 500ms;
    transform-origin: right;
  }

  .course_card_container .card__background {
    transition: transform 500ms ease-in;
  }

  .course_card_container .card__content--container > :not(.card__title),
  .course_card_container  .card__button {
    opacity: 0;
    transition: transform 500ms ease-out, opacity 500ms ease-out;
  }

  .course_card_container .card:hover,
  .course_card_container .card:focus-within {
    transform: scale(1.05);
    transition: transform 500ms ease-in;
  }

  .course_card_container .card:hover .card__content,
  .course_card_container .card:focus-within .card__content {
    transform: translateY(0);
    transition: transform 500ms ease-in;
  }

  .course_card_container  .card:focus-within .card__content {
    transition-duration: 0ms;
  }

  .course_card_container .card:hover .card__background,
  .card:focus-within .card__background {
    transform: scale(1.3);
  }

  .course_card_container  .card:hover .card__content--container > :not(.card__title),
  .course_card_container .card:hover .card__button,
  .course_card_container  .card:focus-within .card__content--container > :not(.card__title),
  .course_card_container  .card:focus-within .card__button {
    opacity: 1;
    transition: opacity 500ms ease-in;
    transition-delay: 1000ms;
  }

  .course_card_container .card:hover .card__title::after,
  .course_card_container .card:focus-within .card__title::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: opacity 500ms ease-in, transform 500ms ease-in;
    transition-delay: 500ms;
  }
} */



